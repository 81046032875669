/* eslint-disable no-return-assign */
/* eslint-disable no-empty-pattern */
import axios from 'axios'
import { datadogLogs } from '@datadog/browser-logs'
import { authHeadersWithVerificationToken } from '@/utils'

const { authHeaders } = require('../../utils')

const CORE_API_URL = process.env.VUE_APP_CORE_API_URL
const EH_API_URL = process.env.VUE_APP_EH_API_URL

export default {
  namespaced: true,
  state: {
    loading: {
      account: false,
      users: false,
      customer: false,
      payment: false,
      invoices: false,
    },
    account: {},
    schedules: {},
    userStatus: {},
    templateAnalytics: [],
    userAnalytics: [],
    connectionRequestAnalytics: [],
    users: {},
    actions: [],
    actionStats: {},
    tools: {},
    crmFields: {},
    crmOwners: {},
    customer: {},
    activePlan: {},
    invoices: [],
    paymentMethods: {},
    paymentIntents: {},
    enrichmentLogs: [],
    fieldsMappingLogs: [],
    featureFlags: [],
    teams: [],
    ehQueries: [],
  },
  getters: {
    getDefaultFields(state) {
      if (!state.crmFields.defaultFields) return {}
      const fields = {};
      ['contact', 'lead', 'org', 'deal'].forEach(key => {
        fields[key] = (state.crmFields.defaultFields[key] || []).map(field => {
          const af = (state.crmFields.availableFields[key] || []).filter(f => f.id === field.id)[0] || {}
          return { ...field, type: af.type, options: af.options }
        })
      })
      return fields
    },
    getPaymentIntentError(state) {
      const { paymentIntents } = state
      if (!paymentIntents || !Array.isArray(paymentIntents) || paymentIntents.length < 1) return false

      const sorted = paymentIntents.sort((a, b) => b.created - a.created)
      return sorted[0] && !!sorted[0].last_payment_error
    },
  },
  mutations: {
    SET_LOADING(state, key) {
      if (!(key in state.loading)) return
      state.loading[key] = true
    },
    SET_LOADED(state, key) {
      if (!(key in state.loading)) return
      state.loading[key] = false
    },
    UPDATE_ACCOUNT(state, value) {
      state.account = value
    },
    UPDATE_SCHEDULES(state, value) {
      state.schedules = value
    },
    UPDATE_TEMPLATE_ANALYTICS(state, value) {
      state.templateAnalytics = value
    },
    UPDATE_USER_ANALYTICS(state, value) {
      state.userAnalytics = value
    },
    CONNECTION_REQUEST_ANALYTICS(state, value) {
      state.connectionRequestAnalytics = value
    },
    UPDATE_USERS(state, value) {
      state.users = value
    },
    UPDATE_ACTIONS(state, value) {
      state.actions = value
    },
    UPDATE_ACTION_STATS(state, value) {
      state.actionStats = value
    },
    UPDATE_TOOLS(state, value) {
      state.tools = value
    },
    UPDATE_CRM_OWNERS(state, value) {
      state.crmOwners = value
    },
    UPDATE_CRM_FIELDS(state, value) {
      state.crmFields = value
    },
    UPDATE_CUSTOMER(state, value) {
      state.customer = value

      const subscription = value.subscriptions?.data.find(x => x.id === state.account.subscriptionID) || value.subscriptions?.data[0] || {}
      if (!subscription.plan) return {}

      let id = 'basic'

      if (subscription.plan.id.includes('enrich')) id = 'enrichment'
      else if (subscription.plan.id.includes('starter')) id = 'starter'
      else if (subscription.plan.id.includes('professional')) id = 'professional'
      else if (subscription.plan.id.includes('pro_')) id = 'pro'
      else if (subscription.plan.id.includes('essential')) id = 'essential'
      else if (subscription.plan.id.includes('entreprise')) id = 'entreprise'
      else if (subscription.plan.id.includes('business')) id = 'business'

      const PLAN_NAME = {
        ESSENTIAL: 'Essential', BASIC: 'Basic', PRO: 'Pro', TRIAL: 'Trial', FREE: 'Free',
      }

      state.activePlan = {
        id,
        selected: id,
        name: PLAN_NAME[state.account.accountPlan] || id,
        period: subscription.plan.id.includes('monthly') ? 'monthly' : 'yearly',
        periodEnd: subscription.current_period_end,
        licences: subscription.quantity,
        amount: (subscription.plan.amount / 100) * subscription.quantity,
        currency: subscription.plan.currency,
        currencySymbol: subscription.plan.currency === 'eur' ? '€' : '$',
      }
    },
    UPDATE_INVOICES(state, value) {
      state.invoices = value
    },
    UPDATE_PAYMENT_METHODS(state, value) {
      state.paymentMethods = value
    },
    UPDATE_PAYMENT_INTENTS(state, value) {
      state.paymentIntents = value
    },
    UPDATE_ENRICHMENT_LOGS(state, value) {
      state.enrichmentLogs = value
    },
    UPDATE_FIELDS_MAPPING_LOGS(state, value) {
      state.fieldsMappingLogs = value
    },
    UPDATE_FEATURE_FLAGS(state, value) {
      state.featureFlags = value
    },
    UPDATE_TEAMS(state, value) {
      state.teams = value
    },
    UPDATE_EH_QUERIES(state, value) {
      state.ehQueries = value.requests
      state.remainingCredits = value.remainingCredits
    },
    UPDATE_USER_STATUS(state, value) {
      state.userStatus = value
    },
  },
  actions: {
    // Authentication
    async sendMagicLink({ }, values) {
      await axios.post(`${CORE_API_URL}/magic-link`, values)
    },

    // Account
    async loadAccount({ commit }) {
      commit('SET_LOADING', 'account')
      try {
        const url = `${CORE_API_URL}/auth/account`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_ACCOUNT', data)

        if (process.env.VUE_APP_DATADOG_CLIENT_TOKEN) {
          const { email, companyKey } = data

          if (!email) {
            datadogLogs.logger.error('Missing email after fetching user', { email, companyKey })
          }

          if (!companyKey) {
            datadogLogs.logger.error('Missing company key after fetching user', { companyKey, email })
          }
          datadogLogs.addLoggerGlobalContext('email', email)
          datadogLogs.addLoggerGlobalContext('company_key', companyKey)
        }
      } finally {
        commit('SET_LOADED', 'account')
      }
    },
    async loadSchedules({ commit }) {
      commit('SET_LOADING', 'schedules')
      try {
        const url = `${CORE_API_URL}/admin/checkout/schedules`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_SCHEDULES', data)
      } finally {
        commit('SET_LOADED', 'schedules')
      }
    },
    async loadTemplateAnalytics({ commit }) {
      const url = `${CORE_API_URL}/admin/conversation/analytics/templates`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_TEMPLATE_ANALYTICS', data)
    },
    async loadUserAnalytics({ commit }) {
      const url = `${CORE_API_URL}/admin/conversation/analytics/users`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_USER_ANALYTICS', data)
    },
    async loadConnectionRequestAnalytics({ commit }) {
      const url = `${CORE_API_URL}/auth/linkedin/connection-request-stats`
      const { data } = await axios.get(url, authHeaders())
      commit('CONNECTION_REQUEST_ANALYTICS', data)
    },
    async loadTeams({ commit }) {
      const { data } = await axios.get(`${CORE_API_URL}/auth/teams`, authHeaders())
      commit('UPDATE_TEAMS', data)
    },
    async createTeam({ dispatch }, values) {
      await axios.post(`${CORE_API_URL}/admin/teams`, { name: values.name }, authHeaders())
      dispatch('loadTeams')
    },
    async updateAccount({ }, value) {
      const url = `${CORE_API_URL}/auth/account`
      await axios.put(url, value, authHeaders())
    },
    async deleteAccountEvent({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/delete-account`
      await axios.post(url, value, authHeaders())
    },
    async retryStripePayment({ }) {
      const url = `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/retry-payment`
      await axios.post(url, {}, authHeaders())
    },
    async applyPromoCode({ }, promoCode) {
      const url = `${CORE_API_URL}/admin/account/promo-code/${promoCode}`
      const response = await axios.put(url, {}, authHeaders())
      return !!response?.data
    },
    async switchCRM({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/switch-crm`
      await axios.post(url, value, authHeaders())
    },
    async loadNotificationSettings({ commit }, params) {
      const url = `${CORE_API_URL}/notification-settings/${params.userId}/${params.emailId}`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ACCOUNT', data)
    },
    async updateNotificationSettings({ dispatch }, value) {
      const url = `${CORE_API_URL}/notification-settings/${value.userId}/${value.emailId}`
      await axios.put(url, value, authHeaders())
      dispatch('loadNotificationSettings')
    },
    async uninstallEvent({ }, value) {
      const url = `${CORE_API_URL}/uninstall/${value.userId ?? value.email}`
      await axios.post(url, value, authHeaders())
    },
    async loadActions({ commit }, v) {
      const actionType = v ? v.actionType ?? '' : ''
      const duration = v ? v.duration ?? '14' : '14'
      const url = `${CORE_API_URL}/auth/actions?actionType=${actionType}&duration=${duration}`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ACTIONS', data)
    },
    async loadActionsStats({ commit }) {
      console.log('LOADING')
      const url = `${CORE_API_URL}/auth/actions/stats`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ACTION_STATS', data)
    },

    // Users
    async loadUsers({ commit }) {
      commit('SET_LOADING', 'users')
      try {
        const url = `${CORE_API_URL}/auth/users`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_USERS', data)
      } finally {
        commit('SET_LOADED', 'users')
      }
    },
    async loadUserStatus({ commit }) {
      const url = `${CORE_API_URL}/auth/status`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_USER_STATUS', data)
    },
    async inviteUsers({ dispatch }, value) {
      const url = `${CORE_API_URL}/auth/users/invite`
      await axios.post(url, value, authHeaders())
      dispatch('loadUsers')
    },
    async updateUser({ dispatch }, { id, value }) {
      const url = `${CORE_API_URL}/auth/users/${id}`
      await axios.put(url, value, authHeaders())
      dispatch('loadUsers')
    },
    async updateUsers({ dispatch }, { users }) {
      const url = `${CORE_API_URL}/auth/users/batch`
      await axios.put(url, users, authHeaders())
      dispatch('loadUsers')
    },
    async updateUsersSecure({ dispatch }, { users }) {
      const url = `${CORE_API_URL}/auth/users/batch/secure`
      await axios.put(url, users, authHeadersWithVerificationToken())
      dispatch('loadUsers')
    },
    async deleteUser({ dispatch }, id) {
      const url = `${CORE_API_URL}/admin/users/${id}`
      await axios.delete(url, authHeaders())
      dispatch('loadUsers')
    },

    // CRM Fields
    async loadCRMOwners({ commit }) {
      const url = `${CORE_API_URL}/auth/crm-owners?account=true`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_CRM_OWNERS', data)
    },
    async loadEmptyCRMOwners({ commit }) {
      commit('UPDATE_CRM_OWNERS', [])
    },
    async loadCRMFields({ commit }) {
      const url = `${CORE_API_URL}/auth/dashboard/fields/crm`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_CRM_FIELDS', data)
    },
    async updateCRMFields({ }, LinkedinToCRM) {
      const custom = JSON.parse(JSON.stringify(LinkedinToCRM))
      const url = `${CORE_API_URL}/auth/dashboard/fields`
      await axios.put(url, { custom }, authHeaders())
    },
    async updateDefaultFields({ state }, fields) {
      const url = `${CORE_API_URL}/auth/dashboard/fields/default`
      state.crmFields.defaultFields = fields
      await axios.put(url, fields, authHeaders())
    },

    // Tools
    async loadTools({ commit }) {
      const loadUrl = `${CORE_API_URL}/auth/tools`
      const { data } = await axios.get(loadUrl, authHeaders())

      const validateUrl = `${CORE_API_URL}/auth/tools/enrichment/api-account-info`
      const { data: validationData } = await axios.get(validateUrl, authHeaders())

      if (validationData && validationData.length > 0) {
        const updatedData = { ...data }

        validationData.forEach(tool => {
          updatedData.enrichment[tool.name] = { ...data.enrichment[tool.name], ...tool }
        })
        commit('UPDATE_TOOLS', updatedData)
        return
      }

      commit('UPDATE_TOOLS', data)
    },
    async updateTool({ dispatch, state }, value) {
      const url = `${CORE_API_URL}/auth/tools`
      if (!value.category) return

      const currentValue = state.tools[value.category][value.name.toUpperCase()]

      // Swap order if needed
      // eslint-disable-next-line no-restricted-syntax
      for (const [name, tool] of Object.entries(state.tools[value.category])) {
        if (name !== value.name.toUpperCase() && tool.order === value.order) {
          state.tools[value.category][name].order = currentValue.order
        }
      }
      state.tools[value.category][value.name.toUpperCase()] = value

      // When we add a valid tool, we want to make sure we are not adding invalid ones
      if (value.category === 'enrichment') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [name, tool] of Object.entries(state.tools[value.category])) {
          if (name !== value.name.toUpperCase() && tool.isValid !== undefined && !tool.isValid) {
            console.log('tool is invalid, removing api keys')
            state.tools[value.category][name] = { ...state.tools[value.category][name], apiKey: '', apiKey2: '' }
          }
        }
      }

      await axios.put(url, state.tools, authHeaders())
      dispatch('loadTools')
    },
    async toggleTool({ dispatch }, { name, enabled }) {
      const url = `${CORE_API_URL}/auth/tools/toggle`
      await axios.put(url, { name, enabled }, authHeaders())
      dispatch('loadTools')
    },
    async loadEnrichmentLogs({ commit }) {
      const url = `${CORE_API_URL}/auth/enrichment-logs`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_ENRICHMENT_LOGS', data)
    },
    async loadFieldsMappingLogs({ commit }) {
      const url = `${CORE_API_URL}/auth/change-logs?type=update&path=CustomFields`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_FIELDS_MAPPING_LOGS', data)
    },
    async addToolSuggestion({ }, value) {
      const url = `${CORE_API_URL}/auth/feedback/request-tool`
      await axios.post(url, value, authHeaders())
    },
    async disconnectTool({ dispatch }, tool) {
      const url = `${CORE_API_URL}/auth/tool/${tool}`
      await axios.delete(url, authHeaders())
      dispatch('loadTools')
    },
    async verifyToolApiAccountInfo({ commit, state }, value) {
      const updatedData = { ...state.tools.enrichment }
      // We are always updating the tool locally based when API keys are changed.
      updatedData[value.name] = {
        ...updatedData[value.name],
        apiKey: value.info.apiKey,
        apiKey2: value.info.apiKey2,
        isLoading: true,
      }

      commit('UPDATE_TOOLS', { ...state.tools, enrichment: updatedData })

      // If the API Key length is 0, we don't need to verify if the key is valid.
      if (value.info.apiKey.length === 0 || (value.info.bothKeys && value.info.apiKey2 === 0)) {
        return
      }

      const url = `${CORE_API_URL}/auth/tool/${value.name}/api-account-info`
      const { data } = await axios.post(url, value.info, authHeaders())

      // We update the tool with the validation data
      updatedData[data.name] = {
        ...updatedData[data.name],
        ...data,
        isLoading: false,
      }
      commit('UPDATE_TOOLS', { ...state.tools, enrichment: updatedData })
      return data
    },

    // Billing
    async loadCustomer({ commit }) {
      commit('SET_LOADING', 'customer')
      try {
        const url = `${CORE_API_URL}/admin/checkout/customer`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_CUSTOMER', data)
      } finally {
        commit('SET_LOADED', 'customer')
      }
    },
    async updateCustomer({ }, value) {
      const url = `${CORE_API_URL}/admin/checkout/customer`
      await axios.put(url, value, authHeaders())
    },
    async updateSubscription({ dispatch }, value) {
      const url = `${CORE_API_URL}/admin/checkout/v3/subscription`
      await axios.put(url, value, authHeadersWithVerificationToken())
      await dispatch('loadCustomer')
      await dispatch('loadSchedules')
    },
    async cancelSubscription({ dispatch }) {
      const url = `${CORE_API_URL}/admin/checkout/subscription/cancel`
      await axios.put(url, {}, authHeadersWithVerificationToken())
      await dispatch('loadCustomer')
      await dispatch('loadSchedules')
    },
    async undoCancelSubscription({ dispatch }) {
      const url = `${CORE_API_URL}/admin/checkout/subscription/undo-cancel`
      await axios.put(url, {}, authHeadersWithVerificationToken())
      await dispatch('loadCustomer')
      await dispatch('loadSchedules')
    },
    async loadInvoices({ commit }) {
      commit('SET_LOADING', 'invoices')
      try {
        const url = `${CORE_API_URL}/admin/checkout/invoices`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_INVOICES', data)
      } finally {
        commit('SET_LOADED', 'payment')
      }
    },
    async updatePaymentMethod({ }, value) {
      const url = `${CORE_API_URL}/admin/account/pm`
      await axios.put(url, { paymentMethod: value }, authHeaders())
    },
    async updateDefaultPaymentMethod({}) {
      const url = `${CORE_API_URL}/admin/checkout/pm/payment/default`
      await axios.put(url, {}, authHeaders())
    },
    async loadPaymentMethods({ commit }) {
      commit('SET_LOADING', 'payment')
      try {
        const url = `${CORE_API_URL}/admin/checkout/pm`
        const { data } = await axios.get(url, authHeaders())
        commit('UPDATE_PAYMENT_METHODS', data)
      } finally {
        commit('SET_LOADED', 'payment')
      }
    },
    async loadPaymentIntent({ commit }) {
      const url = `${CORE_API_URL}/auth/checkout/pm/payment`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_PAYMENT_INTENTS', data)
    },
    async applyVAT({ }, vat) {
      const url = `${CORE_API_URL}/admin/checkout/vat/${vat}`
      await axios.put(url, {}, authHeaders())
    },
    async applyCoupon({ }, coupon) {
      const url = `${CORE_API_URL}/admin/checkout/coupon/${coupon}`
      const response = await axios.put(url, {}, authHeaders())
      return !!response?.data
    },

    // Enrichment Hub
    async loadEHQueries({ commit }) {
      const url = `${EH_API_URL}/auth/queries`
      const { data } = await axios.get(url, authHeaders(true))
      commit('UPDATE_EH_QUERIES', data)
    },
    async validateQuery({ }, id) {
      const url = `${EH_API_URL}/auth/queries/${id}/validate`
      await axios.patch(url, {}, authHeaders(true))
    },
    async cancelQuery({ }, id) {
      const url = `${EH_API_URL}/auth/queries/${id}/cancel`
      await axios.patch(url, {}, authHeaders(true))
    },

    // All
    async loadAll({ dispatch, state }) {
      const p = [dispatch('loadUsers'), dispatch('loadActions')]

      // Load remaining data if admin
      if (state.account.isAdmin) {
        p.push(dispatch('loadCustomer'))
        p.push(dispatch('loadSchedules'))
        p.push(dispatch('loadPaymentMethods'))
        p.push(dispatch('loadInvoices'))
      }

      p.push(dispatch('loadPaymentIntent'))
      await Promise.all(p)

      if (state.account.hasCRMAccess) {
        try {
          await dispatch('loadCRMOwners')
        } catch (error) {
          console.error(error)
        }
      } else {
        await dispatch('loadEmptyCRMOwners')
      }
    },

    // Feature flags
    async loadFeatureFlags({ commit }) {
      const url = `${CORE_API_URL}/feature-flags`
      const { data } = await axios.get(url, authHeaders())
      commit('UPDATE_FEATURE_FLAGS', data)
    },
  },
}
